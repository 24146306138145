<style></style>
<script>
import {mapGetters} from "vuex";

export default {
  props: ['cftools_id', 'account'],
  components: {
    UserPersona: () => import("@/components/lazy/account/UserPersona"),
  },
  computed: {
    ...mapGetters([
      'getAccountName',
      'getSubscriberStatus'
    ]),
  },
  created() {
    if(!this.getSubscriberStatus()) {
      this.redacted = true;
    }
  },
  data() {
    return {
      redacted: false,
      account_data: {},
    }
  },
}
</script>

<template>
  <div>
    <!-- Card-buttons row. -->
    <div class="row align-items-stretch justify-content-center">
      <div class="col-xl-11">
        <div class="row">
          <template v-if="!redacted">
            <div class="col-xl-3 align-items-stretch">
              <router-link to="/subscribe" class="card-btn">
                <div class="card border border-white">
                  <div class="card-body">
                    <div class="media">
                      <div class="mr-3 align-self-center">
                        <i class="fad fa-tags text-primary mb-0" style="font-size: 24px;"></i>
                      </div>
                      <div class="media-body">
                        <h5 class="">{{$t('account.subscription.title')}}</h5>
                        <span class="mb-0 text-muted"> {{$t('account.subscription.description')}} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-3 align-items-stretch">
              <router-link to="/support" class="card-btn">
                <div class="card border border-white" >
                  <div class="card-body">
                    <div class="media">
                      <div class="mr-3 align-self-center">
                        <i class="fad fa-map-marker-question text-primary mb-0" style="font-size: 38px;"></i>
                      </div>
                      <div class="media-body">
                        <h5 class="">{{$t('account.support.title')}}</h5>
                        <span class="mb-0 text-muted"> {{$t('account.support.description')}} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-3 align-items-stretch">
              <router-link to="/invoices" class="card-btn">
                <div class="card border border-white">
                  <div class="card-body">
                    <div class="media">
                      <div class="mr-3 align-self-center">
                        <i class="fad fa-file-invoice text-primary mb-0" style="font-size: 38px;"></i>
                      </div>
                      <div class="media-body">
                        <h5 class="">{{$t('account.invoices.title')}}</h5>
                        <span class="mb-0 text-muted"> {{$t('account.invoices.description')}} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-3 align-items-stretch">
              <router-link to="/profile" class="card-btn">
                <div class="card border border-white">
                  <div class="card-body">
                    <div class="media">
                      <div class="mr-3 align-self-center">
                        <i class="fad fa-user-circle text-primary mb-0" style="font-size: 30px;"></i>
                      </div>
                      <div class="media-body">
                        <h5 class="">{{$t('account.profile.title')}}</h5>
                        <span class="mb-0 text-muted"> {{$t('account.profile.description')}} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </template>
          <template v-else>
            <div class="col-xl-4 align-items-stretch">
              <router-link to="/subscribe" class="card-btn">
                <div class="card border border-white">
                  <div class="card-body">
                    <div class="media">
                      <div class="mr-3 align-self-center">
                        <i class="fad fa-tags text-primary mb-0" style="font-size: 24px;"></i>
                      </div>
                      <div class="media-body">
                        <h5 class="">{{$t('account.subscription.title')}}</h5>
                        <span class="mb-0 text-muted"> {{$t('account.subscription.description')}} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-4 align-items-stretch">
              <router-link to="/support" class="card-btn">
                <div class="card border border-white" >
                  <div class="card-body">
                    <div class="media">
                      <div class="mr-3 align-self-center">
                        <i class="fad fa-map-marker-question text-primary mb-0" style="font-size: 38px;"></i>
                      </div>
                      <div class="media-body">
                        <h5 class="">{{$t('account.support.title')}}</h5>
                        <span class="mb-0 text-muted"> {{$t('account.support.description')}} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-xl-4 align-items-stretch">
              <router-link to="/invoices" class="card-btn">
                <div class="card border border-white">
                  <div class="card-body">
                    <div class="media">
                      <div class="mr-3 align-self-center">
                        <i class="fad fa-file-invoice text-primary mb-0" style="font-size: 38px;"></i>
                      </div>
                      <div class="media-body">
                        <h5 class="">{{$t('account.invoices.title')}}</h5>
                        <span class="mb-0 text-muted"> {{$t('account.invoices.description')}} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- Card-buttons row end. -->
    <div class="row justify-content-center">
      <!-- User persona. -->
      <div class="col-lg-5 d-flex">
        <div class="card flex-fill">
          <div class="card-body">
            <h4 class="card-title">
              {{ $t('account.persona.title') }}
              <small class="text-muted">
                {{ $t('account.persona.description') }}
              </small>
            </h4>
            <hr>
            <UserPersona></UserPersona>
          </div>
        </div>
      </div>
      <!-- User persona end. -->
      <!-- Overview. -->
      <div class="col-lg-6 d-flex">
        <div class="card flex-fill">
          <div class="card-body">
            <h4 class="card-title">{{ $t('account.overview.title') }}</h4>
            <hr>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="cf-id">{{ $t('account.overview.cftools_id') }}</label>
                  <input
                      id="cf-id"
                      type="text"
                      class="form-control"
                      placeholder="First name"
                      :value="account.cftools_id"
                      disabled="disabled"
                      style="font-family: 'Courier New'"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="cf-username">{{ $t('account.overview.username') }}</label>
                  <input
                      id="cf-username"
                      type="text"
                      class="form-control"
                      :value="getAccountName()"
                      disabled="disabled"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Overview end. -->
    </div>
  </div>
</template>